html,
body {
  color: white;
  background: #181e2b;
}

::placeholder {
  color: #a0afc0;
}

* {
  box-sizing: border-box;
}

.separator.regular {
  margin-bottom: 40px;
}

.separator.tiny {
  margin-bottom: 32px;
}

.angle {
  position: absolute;
  width: 35%;
}

.angle.top-right {
  top: -5px;
  right: -5px;
}

.angle.bottom-left {
  bottom: -5px;
  left: -5px;
}

img {
  user-select: none;
  pointer-events: none;
}

@import './fonts.css';
@import './fixes.css';
@import './animations.css';
@import './nprogress.css';
@import './slick.css';
