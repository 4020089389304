@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
  }
  to {
    opacity: 0;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(50px);
  }
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scrollLeft {
  animation: scrollLeft linear infinite;
}

.scrollRight {
  animation: scrollRight linear infinite;
}

.fadeInDown {
  animation-name: fadeInDown;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.fadeOutDown {
  animation-name: fadeOutDown;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.fadeIn {
  animation-name: fadeIn;
  transition-timing-function: linear;
  animation-fill-mode: forwards;
}

.fadeOut {
  animation-name: fadeOut;
  transition-timing-function: linear;
  animation-fill-mode: forwards;
}

.slideUp {
  animation-name: slideUp;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.slideDown {
  animation-name: slideDown;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
}

.cursor {
  animation: cursor 0.8s ease-out infinite;
}
