@font-face {
  font-family: NeueHansKendrick;
  font-weight: 400;
  font-display: swap;
  src: url(../../public/fonts/NeueHansKendrick-Regular.woff) format('woff');
}

@font-face {
  font-family: NeueHansKendrick;
  font-weight: 500;
  font-display: swap;
  src: url(../../public/fonts/NeueHansKendrick-Medium.woff) format('woff');
}

@font-face {
  font-family: NeueHansKendrick;
  font-weight: 700;
  font-display: swap;
  src: url(../../public/fonts/NeueHansKendrick-Bold.woff) format('woff');
}

html {
  font-family: NeueHansKendrick, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
