body,
html {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  overflow: auto;
  overflow-x: hidden;
}

#__next {
  overflow: hidden;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
}

p {
  /* margin: 0; */
  margin-top: 0;
}

a {
  background: none;
  text-decoration: none;
}

button {
  background: none;
  appearance: none;
  overflow: visible;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
